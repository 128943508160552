.contact-link {
	font-weight: normal;
	text-decoration: none;
	word-break: break-word;
	word-wrap: break-word;
	font-family: "Inter var", sans-serif;
}

.contact-link:hover {
    text-decoration: underline;
}