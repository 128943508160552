/* gray */
/* var(--color-gray-100): #e0e4e7 */
/* var(--color-gray-200): #c1c9d0 */
/* var(--color-gray-300): #a2adb8 */

.dropdown-container {
	padding: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.arrow-container {
    padding: 8px;
    border-radius: 4px;
    background-color: #e0e4e7; /* var(--color-gray-100) */
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow-container:hover {
    background-color: #c1c9d0; /* var(--color-gray-200) */
}

.arrow-container.active {
    background-color: #a2adb8; /* var(--color-gray-300) */
}