.asset-detail-view {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 2rem;
	align-items: center;
}
.video-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
.video-container .video-iframe {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border: 0 none;
	border-radius: 4px;
}
.video-container .video-image {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border: 0 none;
}
.video-container .video-image.show {
	display: block;
}
.video-container .video-image.hide {
	display: none;
}
.video-container .video-image:hover {
	cursor: pointer;
}
.video-container .video-image:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(41, 48, 54, 0.45);
	z-index: 0;
	mix-blend-mode: multiply;
	border-radius: 4px;
}
.video-container .video-image img {
	width: 100%;
	height: 100%;
	border-radius: 4px;
}
.video-container .play-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}
.content-container .headline {
	margin-top: 1rem;
	max-width: 832px;
}
.content-container .description {
	margin-top: 1rem;
	max-width: 768px;
}
.content-container .asset-details {
	margin-top: 1rem;
	max-width: 768px;
}
.content-container .button {
	margin-top: 2rem;
}
