.page-header {
	display: flex;
	justify-content: center;
	background-color: var(--color-gray-50);
}
.page-header-container {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: left;
	position: relative;
	min-height: 500px;
	width: 100%;
}
.page-header-container.subpage {
	min-height: 400px;
}
.page-header-container .heading {
	word-break: break-word;
}
.page-header-container .subheading {
	margin-top: 1rem;
}
.page-header-container .subheading p {
	margin: 0;
}
.page-header-container .buttons {
	margin-top: 2rem;
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
}

.page-header-container .page-header-content {
	color: var(--color-gray-800);
}
.page-header-container .page-header-content p a {
	color: var(--color-primary);
	text-decoration: none;
}
.page-header-container .page-header-content {
	flex: 1 1 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 64px 2rem;
	min-width: 0;
}
.page-header-container .imageContainer {
	flex: 1 1 300px;
	position: relative;
}
.page-header-container .imageContainer .heroImage {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
}

@media only screen and (min-width: 768px) {
	.page-header-container .page-header-content {
		padding: 64px 2rem;
	}
	.page-header-container .imageContainer {
		width: 100%;
	}
}

@media only screen and (min-width: 1024px) {
	.page-header-container {
		flex-direction: row;
		min-height: 500px;
		align-items: stretch;
		gap: 48px;
		padding: 0;
	}
	.page-header-container .page-header-content {
		max-width: calc(100% / 2 - 24px);
		padding: 64px 0px 64px calc(max(2rem, 100% / 2 - (1280px / 2) + 2rem));
		flex: 1 1 50%;
	}
	.page-header-container .imageContainer {
		flex: 1 1 50%;
	}
}
