/* blue */
/* var(--color-blue-500): #0065bd */
/* var(--color-blue-600): #004c9a */

/* gray */
/* var(--color-gray-50): #f7f8f9 */
/* var(--color-gray-100): #e0e4e7 */
/* var(--color-gray-800): #293036 */

/* overall container, centers content, adds active bottom border*/
.primary-menu-item {
	text-decoration: none;
	display: flex;
	align-items: center;
	padding-top: 8px;
	padding-bottom: 8px;
	height: 100%;
	border-bottom: 2px solid transparent;
	z-index: 99999; /* account for sticky anchor nav*/
}

.primary-menu-item.selected {
	position: relative;
}

.primary-menu-item.active-item {
	border-bottom: 2px solid var(--nav-tab-accent-color);
}

/* positions arrow, hover state with rounded corners */
.primary-menu-item-content {
	padding: 12px 14px;
	display: flex;
	flex-direction: row;
	border-radius: 4px;
	cursor: pointer;
}

.primary-menu-item-content:visited,
.primary-menu-item-content:focus,
.primary-menu-item-content:active,
.primary-menu-item-content.active {
	background-color: #e0e4e7; /* var(--color-gray-100) */
	color: #293036; /* var(--color-gray-800) */
	text-decoration: none;
}

.primary-menu-item-content:hover {
	color: #293036; /* var(--color-gray-800) */
	text-decoration: none;
	background-color: #f7f8f9; /* var(--color-gray-50) */
}

.arrow-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 4px;
}

.secondary-menu-container {
	margin-top: 4px;
	border-radius: 4px;
	background-color: white;
	position: absolute;
	top: 100%; /* Position it right below the parent item */
	left: -14px;
	z-index: 1000; /* Make sure it appears over other content */
	width: 200px; /* Adjust as needed */
	box-shadow: 0px 3px 16px 0px rgba(41, 48, 54, 0.2);
	list-style: none;
	padding: 4px 16px;
	min-width: 308px;
}

.secondary-menu-container li {
	background-color: white;
}

.secondary-menu-container li:hover {
	color: #0065bd; /* var(--color-blue-500) */
	background-color: #f7f8f9; /* var(--color-gray-50) */
}

.secondary-menu-container li:active,
.secondary-menu-container li.active {
	color: #004c9a; /* var(--color-blue-600) */
	background-color: #e0e4e7; /* var(--color-gray-100) */
}

/* Mega Menu */
.mega-menu-container {
	margin-top: 4px;
	border-radius: 4px;
	background-color: white;
	position: absolute;
	top: 100%; /* Position it right below the parent item */
	left: -14px;
	z-index: 1000; /* Make sure it appears over other content */
	width: 735px; /* Adjust as needed */
	box-shadow: 0px 3px 16px 0px rgba(41, 48, 54, 0.2);
	list-style: none;
	padding: 4px 16px;
    	/*new styles*/
	display: flex;
	gap: 1rem;
    justify-content: space-between;
}

.mega-menu-container > li {
	flex:1;
}

.mega-menu-container .primary-link div {
    font-weight: 600;
}

.mega-menu-container .sub-link div {
    font-weight: normal;
}

.mega-menu-container li {
	background-color: white;
}

.mega-menu-container li a:hover {
	color: #0065bd; /* var(--color-blue-500) */
	background-color: #f7f8f9; /* var(--color-gray-50) */
}

.mega-menu-container li a:active,
.mega-menu-container li a.active {
	color: #004c9a; /* var(--color-blue-600) */
	background-color: #e0e4e7; /* var(--color-gray-100) */
}

.nav-link {
	display: block;
	text-decoration: none;
	color: #293036; /* var(--color-gray-800) */
	padding: 8px 12px;
}

.primary-nav-link {
	display: block;
	text-decoration: none;
	color: #293036; /* var(--color-gray-800) */
}

.nav-link:active,
.nav-link.active {
	text-decoration: none;
	color: #004c9a; /* var(--color-blue-600) */
}

.nav-link:hover {
	text-decoration: none;
	color: #0065bd; /* var(--color-blue-500) */
}
