.asset-detail-view {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
}
.image-container {
	display: block;
	order: -1;
}
.image-container .image {
	aspect-ratio: unset;
	display: block;
	width: 100%;
	height: auto;
	object-fit: contain;
	object-position: left center;
	border-radius: 4px;
}
.content-container {
	padding: 2rem 0 0 0;
}
.content-container .headline {
	margin-top: 1rem;
	max-width: 832px;
}
.content-container .description {
	margin-top: 1rem;
	max-width: 768px;
}
.content-container .asset-details {
	margin-top: 1rem;
	max-width: 768px;
}
.content-container .button {
	margin-top: 2rem;
}

@media only screen and (min-width: 1024px) {
	.asset-detail-view {
		grid-template-columns: repeat(2, 1fr);
	}
	.content-container {
		padding: 0 3rem 0 0;
	}
	.image-container {
		order: 0;
	}
	.image-container .image {
		aspect-ratio: 4 / 3;
		object-position: center center;
	}
}
