.headline {
	max-width: 832px;
	margin-top: 1rem;
}
.subheadline {
	max-width: 768px;
	margin-top: 1rem;
}
.button {
	margin-top: 2rem;
	margin-right: 1.5rem;
}
.error-page {
	background-color: var(--color-gray-50);
}
